import BasePlugin from '../BasePlugin'

export default class LoadFileECommerce extends BasePlugin {
  async execute () {
    this.context.$loading()
    let me = this
    let address = `${this.context.$config.api}/files/d15d8201-5e6a-4a8e-a5a2-f4d421725596.pptx`
    this.context.$http({
      method: 'get',
      url: address,
      responseType: 'arraybuffer'
    }).then((response2) => {
      this.openExportAttachment(response2.data, me, 'pptx', 'Анализ исполнения_Блок e-commerce 8 мес. 2020 г..pptx')
      this.context.$loading().close()
    })
  }
}
